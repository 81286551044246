<template>
  <div>
    <div v-for="(elem, index) in dashboard" :key="index">
      <div v-if="hasPrimission(elem)" class="card-title">
        <h1>{{ elem.title }}</h1>
      </div>
      <el-row class="card-item">
        <el-col
          :xs="12"
          :sm="12"
          :md="6"
          v-for="(item, key) in elem.items"
          :key="key"
          v-show="permissionAllow(item.auths)"
        >
          <!-- 如果此 item 是沒有任何角色限制 -->
          <div class="item">
            <el-card shadow="hover">
              <div class="card-body">
                <!-- 如果此 item 是 Line Login -->
                <div
                  v-if="item.name === 'greet.line_login'"
                  style="height:100%"
                >
                  <a :href="lineLoginAuthUrl" class="card-content">
                    <div style="font-size: 100px">
                      <i class="fab" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>{{ $t(item.name) }}</span>
                    </div>
                  </a>
                </div>
                <!-- 如果此 item 不是 Line Login -->
                <div v-else style="height:100%">
                  <router-link
                    v-if="item.routerName"
                    class="card-content"
                    :to="{
                      name: item.routerName,
                      query: item.query ? item.query : {}
                    }"
                  >
                    <div class="font-icon">
                      <i class="fas" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>
                        {{ item.name === "greet.practices" ? $t(profile.all_roles.includes("demo-role") ? "SAT" : item.name) : $t(item.name) }}
                      </span>
                    </div>
                  </router-link>
                  <a
                    v-else
                    class="card-content"
                    :href="item.path"
                  >
                    <div class="font-icon">
                      <i class="fas" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>
                        {{ item.name === "greet.practices" ? $t(profile.all_roles.includes("demo-role") ? "SAT" : item.name) : $t(item.name) }}
                      </span>
                    </div>
                  </a>

                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */

import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: "Home - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      isBindLineLogin: true,
      lineLoginAuthUrl: null,
      dashboard: [
        {
          title: "Classes",
          items: [
            {
              // My Classes
              name: "pageTitle.myClasses",
              routerName: "myClasses",
              auths: ["all"],
              icon: "fa-copyright"
            },
            {
              // Applications
              name: "pageTitle.applications",
              routerName: "applications",
              auths: ["super-admin"],
              icon: "fa-marker"
            },
            {
              // Counseling
              name: "pageTitle.studentsAttendances",
              routerName: "studentsAttendances",
              auths: ["super-admin", "teacher"],
              icon: "fa-user-check"
            },
            {
              // Report Status
              name: "pageTitle.reportStatuses",
              routerName: "reportStatuses",
              auths: ["super-admin"],
              icon: "fa-file-alt"
            },
            {
              // Enrollment
              name: "admin.enrollment",
              routerName: "EnrollmentList",
              auths: ["super-admin"],
              icon: "fa-envelope-open-text"
            },
            {
              // Placement
              name: "admin.placement_management",
              routerName: "Arrange",
              auths: ["super-admin"],
              icon: "fa-calendar-day"
            },
            {
              // quick enroll
              name: "greet.quick_enroll",
              routerName: "QuickEnrollNew",
              auths: ["super-admin", "parent", "student"],
              icon: "fas fa-file-signature"
            }
          ]
        },
        {
          title: "Tests",
          items: [
            {
              // My Tests
              name: "greet.myTests",
              routerName: "GetTests",
              auths: ["all"],
              icon: "fa-file-alt"
            },
            {
              // Grading
              name: "greet.setTest",
              routerName: "SetStudentTest",
              auths: ["super-admin", "teacher","demo-role"],
              icon: "fa-superscript"
            },
            {
              // Practices
              name: "greet.practices",
              routerName: "Practices",
              auths: ["super-admin", "teacher", "student","demo-role"],
              icon: "fa-file-alt"
            },
            {
              // Timer
              name: "admin.timer",
              routerName: "Timer",
              auths: ["all","demo-role"],
              icon: "fas fa-clock"
            },
            {
              // Answers
              name: "greet.tests",
              routerName: "Tests",
              auths: ["super-admin", "test-editor", "teacher"],
              icon: "fa-file-alt"
            },
            {
              // Answers
              name: "greet.questions",
              routerName: "Questions",
              auths: ["super-admin", "test-editor", "teacher"],
              icon: "fas fa-book"
            }
            // {
            //   // TOEFL
            //   name: "greet.toefl",
            //   routerName: "ToeflList",
            //   auths: ["all", "demo-role"],
            //   icon: "fa-pencil-alt"
            // }
          ]
        },
        {
          title: "Resources",
          items: [
            {
              // Blog
              name: "greet.blog",
              routerName: "Blogs",
              auths: ["all","demo-role"],
              icon: "fa-external-link-alt"
            },
            {
              // Flash Cards
              name: "greet.flashcards",
              routerName: "Vocabs",
              auths: ["all","demo-role"],
              icon: "fa-book"
            },
            {
              // SchoolsList
              name: "greet.colleges",
              routerName: "SchoolsList",
              query:{"rank":12},
              auths: ["all","demo-role"],
              icon: "fa-graduation-cap"
            },
            {
              name: "pageTitle.collegeAliases",
              routerName: "collegeAliases",
              auths: ["super-admin", "administrative-assistant"],
              icon: "fa-university"
            },
            {
              name: "pageTitle.attachLinkToSchool",
              routerName: "attachLinkToSchool",
              auths: ["super-admin", "administrative-assistant"],
              icon: "fa-university"
            },
            {
              name: "pageTitle.postCreator",
              routerName: "postCreator",
              auths: ["super-admin", "administrative-assistant"],
              icon: "fa-university"
            }
          ]
        },
        {
          title: "Billing",
          items: [
            {
              name: "admin.tuition",
              routerName: "Bill",
              auths: ["super-admin", "parent"],
              icon: "fa-hand-holding-usd"
            },
            {
              name: "admin.salary",
              routerName: "Salary",
              auths: ["super-admin", "teacher"],
              icon: "fa-money-bill-alt"
            },
            {
              name: "admin.payment",
              routerName: "payment",
              auths: ["super-admin"],
              icon: "fa-wrench"
            },
            {
              name: "pageTitle.monetaryStatement",
              routerName: "monetaryStatement",
              auths: ["super-admin"],
              icon: "fa-file-invoice-dollar"
            },
            {
              name: "pageTitle.stripe",
              routerName: "Stripe",
              auths: ["admin"],
              icon: "fas fa-stripe"
            }
          ]
        },
        {
          title: "Online Payment",
          items: [
            {
              name: "pageTitle.Deposit",
              routerName: "stripeDeposit",
              auths: ["all"],
              icon: "fas fa-wallet"
            }
          ]
        },
        {
          title: "Users",
          items: [
            {
              // Profile
              name: "header.profile",
              routerName: "Profile",
              auths: ["all"],
              icon: "fa-user"
            },
            {
              // Bind LINE Login
              name: "greet.line_login",
              auths: ["all","demo-role"],
              icon: "fa-line"
            },
            {
              // Profile
              name: "pageTitle.UserList",
              routerName: "UserList",
              auths: ["teacher", "parent", "super-admin","demo-role"],
              icon: "fas fa-users"
            },
            {
              name: "pageTitle.MergeUser",
              routerName: "MergeUser",
              auths: ["super-admin"],
              icon: "fas fa-compress-alt"
            }
          ]
        },
        {
          title: "Setting",
          items: [
            {
              // Courses
              name: "admin.courses_management",
              routerName: "courseIntrosList",
              auths: ["super-admin"],
              icon: "fa-atom"
            },
            {
              // Courses
              name: "admin.stories_management",
              routerName: "StoriesList",
              auths: ["super-admin"],
              icon: "far fa-comment-alt"
            },
            {
              // Course Sessions
              name: "admin.course_sessions_management",
              routerName: "courseSessionsList",
              auths: ["super-admin"],
              icon: "fa-code-branch"
            },
            {
              // Session Classes
              name: "admin.session_classes_management",
              routerName: "sessionClassesList",
              auths: ["super-admin"],
              icon: "fa-copyright"
            },
            {
              name: "greet.edit_faq",
              routerName: "EditFaq",
              auths: ["super-admin"],
              icon: "fa-pen-square"
            },
            {
              name: "pageTitle.tags",
              routerName: "tags",
              auths: ["super-admin"],
              icon: "fas fa-tags"
            },
            {
              name: "pageTitle.shortUrls",
              routerName: "shortUrls",
              auths: ["super-admin"],
              icon: "fas fa-link"
            }
          ]
        },
        {
          title: "Email",
          items: [
            {
              name: "email.compose",
              routerName: "EmailCompose",
              auths: ["super-admin"],
              icon: "fa-envelope"
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    sat() {
      return this.$route.query.sat;
    }
  },
  watch: {},

  async mounted() {
    if(this.sat && this.sat === "sat") {
      window.location.href = `https://sat.ivy-way.com?token=${this.token}`;
    }
    if (!this.isLogin) {
      this.$router.push({ name: "LandingPage" });
    }

    try {
      if (!this.profile || this.profile.all_roles == null) {
        await this.getProfile();
      }
    } catch (error) {
      this.$router.push({
        name: "Login"
      });
    }
    await this.fetchLineLoginAuthURL();
    this.dashboard[1].items.push({
      // SAT
      name: "greet.toefl",
      path: "https://testadmit.com/",
      auths: ["all", "demo-role"],
      icon: "fa-pencil-alt"
    });
    this.dashboard[1].items.push({
      // SAT
      name: "greet.SAT",
      path: `https://sat.ivy-way.com?token=${this.token}`,
      auths: ["all", "demo-role"],
      icon: "fa-pencil-alt"
    });
  },

  methods: {
    hasPrimission({ items }) {
      return items.some(item => {
        return this.permissionAllow(item.auths);
      });
    },
    async fetchLineLoginAuthURL() {
      let url = await this.$router.resolve({
        name: "BindLineLogin"
      });
      let lineLoginAuthUrl = await lineLoginApis.getAuthURL({
        redirect_uri: window.location.origin + url.href
      });
      this.lineLoginAuthUrl = lineLoginAuthUrl.url;
    },
    permissionAllow(allowRoles) {
      if(_.includes(this.profile.all_roles, "demo-role"))
      {
        if(_.includes(allowRoles, "demo-role"))
        {
          if (_.includes(allowRoles, "all")) {
            return true;
          }

          return _.intersection(this.profile.all_roles, allowRoles).length > 1;
        }
        return false;
      }

      if (_.includes(allowRoles, "all")) {
        return true;
      }

      return _.intersection(this.profile.all_roles, allowRoles).length > 0;
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.bread-header {
  margin: 0 50px;
}
.card-title {
  margin-left: 50px;
}
.card-item {
  margin-left: 25px;
  margin-right: 25px;
}
.font-icon {
  font-size: 100px;
}
.item {
  padding: 20px;
}
.center-row {
  height: 20vh;
}

.greet-color {
  color: #42a16a;
}

.greet-center {
  text-align: center;
}

a {
  text-decoration: none;
  color: #909399;
}

.card-body {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 5px;
  height: 180px;
  text-align: center;
}

.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
    margin-top: 5px;
  }
  .card-title {
    margin-left: 20px;
  }
  .card-item {
    margin: 0 10px;
  }
  .item {
    padding: 10px;
  }
  .font-icon {
    font-size: 70px;
  }
  .card-body {
    height: 160px;
  }
  .bread-header {
    margin: 0 20px;
  }
}
</style>
